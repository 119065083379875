
















export default {
  // metaInfo: getMetaInfo('school'),
  // components: {DrashTooltip}
}
